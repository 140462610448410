import { GENDERS, OpenOrgEvent, OpenOrgEventId, OpenOrgEventRegistration, OrgId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import moment from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants";
import _ from "lodash";

export async function openOrgEvents__server__getOrgsIdsWithRecentOrgEvents(p: {
  orgIds: string[];
  type: "tryout" | "camp" | "genericEvent";
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  return _.uniq(
    (
      await h.OpenOrgEvent.multiQuery({
        queries: p.orgIds.map(orgId => {
          return {
            where: [
              { orgId: ["==", orgId] },
              { type: ["==", p.type as "tryout" | "camp" | "genericEvent"] },
              { signupAvailabilityStartDatetime: [">=", moment().subtract(120, "days").format(MOMENT_DATE_TIME_FORMAT)] },
              { signupAvailabilityStartDatetime: ["<", moment().format(MOMENT_DATE_TIME_FORMAT)] }
            ]
          };
        })
      })
    ).docs.map(openOrgEvent => openOrgEvent.orgId)
  );
}

openOrgEvents__server__getOrgsIdsWithRecentOrgEvents.auth = () => {};
