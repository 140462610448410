import { validateSelfAccountId } from "../../internal-utils/server-auth";
import { getServerHelpers } from "../../helpers";
import {
  OpenOrgEventRegistrationQuestionType,
  GENDERS,
  OpenOrgEventId,
  OpenOrgEventRegistration,
  OrgTeamTag,
  PrettyPlayer,
  Team
} from "@ollie-sports/models";
import { OpenOrgEventGetRegistrationProps, getOpenOrgEventRegistrations, shouldSendEmail } from "../../utils";
import _ from "lodash";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { dateFormatters, translate } from "@ollie-sports/i18n";
import moment from "moment";

//We generate the export string on the client because it's an uber pain to
//assemble all needful data and we already have the data on the frontend.
export async function openOrgEvents__server__exportRegistrants(
  p: Omit<OpenOrgEventGetRegistrationProps, "openOrgEventIds"> & { email: string; openOrgEventIds: OpenOrgEventId[] }
) {
  // SERVER_ONLY_TOGGLE
  const {
    injectedServerLibraries: { sendGrid },
    appOllieFirestoreV2: h
  } = getServerHelpers();
  const [accountPrivate, openOrgEventData, openOrgEventData2, registrationData, orgTeamTagsData] = await Promise.all([
    h.AccountPrivate.getDoc(p.selfAccountId),
    h.OpenOrgEvent.getDocs(p.openOrgEventIds),
    h.OpenOrgEvent.query({ where: [{ orgId: ["==", p.orgId] }] }),
    getOpenOrgEventRegistrations({
      ...p,
      openOrgEventIds: p.openOrgEventIds.reduce((acc, val) => {
        acc[val] = true;
        return acc;
      }, {} as Record<string, true>)
    }),
    h.OrgTeamTag.query({ where: [{ orgId: ["==", p.orgId] }] })
  ]);
  const registrations = registrationData.registrations;
  const openOrgEvents = _.compact(openOrgEventData).length ? _.compact(openOrgEventData) : openOrgEventData2.docs;
  const orgTeamTags = orgTeamTagsData.docs;
  const openOrgEventIdToTeamTagMapping = openOrgEvents.reduce((acc, event) => {
    const orgTeamTag = event.orgTeamTagId ? orgTeamTags.find(ott => ott.id === event.orgTeamTagId) : undefined;
    if (orgTeamTag) {
      acc[event.id] = orgTeamTag;
    }
    return acc;
  }, {} as Record<string, OrgTeamTag>);

  const allCustomSections = openOrgEvents.reduce((acc, val) => {
    val?.customSections?.forEach(section => {
      if (section.type !== OpenOrgEventRegistrationQuestionType.explainer) {
        if (acc[section.shortTitle]) {
          acc[section.shortTitle] = [...acc[section.shortTitle], section.id];
        } else {
          acc[section.shortTitle] = [section.id];
        }
      }
    });
    return acc;
  }, {} as Record<string, string[]>); // Record<title, ids with that title

  const locale = accountPrivate?.communicationLocale ?? "en-us";
  const subject =
    p.type === "tryout"
      ? translate({ defaultMessage: `Tryout Registrations`, serverLocale: locale })
      : p.type === "camp"
      ? translate({ defaultMessage: `Camp Registrations`, serverLocale: locale })
      : translate({ defaultMessage: `Open Event Registrations`, serverLocale: locale });
  let exportString = `${translate.common(locale).FirstName},${translate.common(locale).LastName},${translate({
    defaultMessage: "ID",
    serverLocale: locale
  })},${translate({ defaultMessage: "Event Birth Year", serverLocale: locale })},${translate({
    defaultMessage: "Event Gender",
    serverLocale: locale
  })},${translate({
    defaultMessage: "Category",
    serverLocale: locale
  })},${translate.common(locale).DateOfBirth},${translate.common(locale).Gender},${translate({
    defaultMessage: "Guardian First Name",
    serverLocale: locale
  })},${translate({
    defaultMessage: "Guardian Last Name",
    serverLocale: locale
  })},${translate({ defaultMessage: "Guardian Email", serverLocale: locale })},${translate({
    defaultMessage: "Guardian Phone Number",
    serverLocale: locale
  })},${translate({
    defaultMessage: "Signup Date",
    serverLocale: locale
  })}${Object.keys(allCustomSections)
    ?.map(customSectionTitle => {
      return `,${customSectionTitle}`;
    })
    .join("")}\n`;

  registrations.forEach(r => {
    const orgTeamTag = openOrgEventIdToTeamTagMapping[r.openOrgEventId] ?? undefined;
    const openOrgEvent = openOrgEvents.find(event => event.id === r.openOrgEventId);
    exportString += `${r.playerInfo.firstName},${r.playerInfo.lastName},${r.playerInfo.temporaryIdentifier ?? ""},${
      r.tryoutInfo.sessionSelection.birthYear
    },${r.tryoutInfo.sessionSelection.gender},${orgTeamTag ? `${orgTeamTag.tag}` : ""},${r.playerInfo.dateOfBirth},${
      r.playerInfo.gender
    },${r.guardianContactInfo.firstName},${r.guardianContactInfo.lastName},${r.guardianContactInfo.email},${
      r.guardianContactInfo.phoneNumber
    },${dateFormatters.m_d_yy_t_tt_a(moment(r.createdAtMS).toDate(), locale).replace(",", "")}${Object.keys(allCustomSections)
      ?.map(customSectionTitle => {
        const customSectionIds = allCustomSections[customSectionTitle];
        const response = r.customSectionResponses?.find(customSectionResponses =>
          customSectionIds.includes(customSectionResponses.customSectionId)
        );

        let responseString = "";
        if (response) {
          switch (response.type) {
            case OpenOrgEventRegistrationQuestionType.checkbox:
              responseString = response.selections
                .map(s => {
                  return s.replace(/\n/g, ` `).replace(/,/g, ` `);
                })
                .join(":");
              break;
            case OpenOrgEventRegistrationQuestionType.radio:
              responseString = response.selection.replace(/\n/g, ` `).replace(/,/g, ` `);
              break;
            case OpenOrgEventRegistrationQuestionType.freeResponse:
              responseString = response.response.replace(/\n/g, ` `).replace(/,/g, ` `);
              break;
            case OpenOrgEventRegistrationQuestionType.legalDoc:
              responseString = `${moment(response.agreedToAtMS).format("MM/DD/YYYY")}`;
              break;
          }
        }
        return `,${responseString}`;
      })
      .join("")}\n`;
  });

  if (shouldSendEmail(p.email)) {
    await sendGrid.send({
      from: "Ollie Sports <noreply@olliesports>",
      to: p.email,
      subject,
      ipPoolName: "Transactional",
      html: `
    <!DOCTYPE html>
    <html>
    <head>
    <meta charset="utf-8">
    <title>${
      p.type === "tryout"
        ? translate({ defaultMessage: "Tryout Registration Export", serverLocale: locale })
        : p.type === "camp"
        ? translate({ defaultMessage: "Camp Registration Export", serverLocale: locale })
        : translate({ defaultMessage: "Open Event Registration Export", serverLocale: locale })
    }</title>
    <meta name="description" content="${subject}">
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    </head>
    <body>
    <p>${
      p.type === "tryout"
        ? translate({
            defaultMessage: "Attached to this email you will find your exported tryout registrations",
            serverLocale: locale
          })
        : p.type === "camp"
        ? translate({
            defaultMessage: "Attached to this email you will find your exported camp registrations",
            serverLocale: locale
          })
        : translate({
            defaultMessage: "Attached to this email you will find your exported open event registrations",
            serverLocale: locale
          })
    }.</p>
    </body>
    </html>
    `,
      attachments: [
        {
          content: Buffer.from(exportString).toString("base64"),
          filename:
            p.type === "tryout"
              ? `tryout-registration-export.csv`
              : p.type === "camp"
              ? `camp-registration-export.csv`
              : `open-event-registration-export.csv`,
          type: "application/csv",
          disposition: "attachment",
          contentId:
            p.type === "tryout" ? "tryoutRegistrations" : p.type === "camp" ? "campRegistrations" : "openEventRegistrations"
        }
      ]
    });
  } else {
    console.log(
      `Not sending email because ${p.email} is either not whitelisted or this is prod and the email is an ollie-testing email.`
    );
  }
  // SERVER_ONLY_TOGGLE
}

openOrgEvents__server__exportRegistrants.auth = async (req: any) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};
// i18n certified - complete
